<template>
    <div class="vip-exchange-container">
        <div class="vip-exchange-top">
            <van-nav-bar title="兑换套餐" fixed/>
        </div>
        <div class="vip-exchange-search-form">
            <van-cell-group>
                <van-field
                        v-model="info.id"
                        type="number"
                        required
                        clearable
                        label="券号"
                        placeholder="请输入11位券号"
                        maxlength="14"
                />
                <van-field
                        v-model="info.pwd"
                        type="number"
                        required
                        clearable
                        label="券码"
                        placeholder="请输入券码后6位"
                        maxlength="6"
                />
                <van-button type="info" icon="search" size="large" @click="onSearch" text="查询"/>
            </van-cell-group>
        </div>
        <div class="vip-exchange-detail">
            <detail :param.sync="info" :key="info.pid" v-if="info.pid > 0"/>
        </div>
    </div>
</template>

<script>
    import detail from './detail'

    export default {
        name: 'vip-exchange-search',
        components: {
            detail
        },
        props: ['node', 'param'],
        data() {
            return {
                info: {
                    pid: 0,
                    id: '',
                    pwd: ''
                }
            }
        },
        methods: {
            onSearch() {
                let that = this;
                if (!that.info.id || that.info.id.length < 11) {
                    return that.$toast('请输入11位券号')
                }
                if (!that.info.pwd || that.info.pwd.length < 6) {
                    return that.$toast('请输入券码后6位')
                }
                that.$api.company.exchange.search({
                    id: that.info.id,
                    pwd: that.info.pwd
                }).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.info.pid = res.data.id
                    } else {
                        that.info.pid = 0;
                        that.$toast(res.msg)
                    }
                })
            }
        }
    }
</script>

<style scoped>

    .vip-exchange-search-form {
        position: fixed;
        width: 100%;
        top: 46px;
    }

    .vip-exchange-detail {
        position: fixed;
        width: 100%;
        top: 184px;
        bottom: 0;
        overflow-y: scroll;
    }

</style>
