<template>
    <div class="vip-exchange">
        <!-- 企业名称 -->
        <van-divider>{{info.master.title}}</van-divider>
        <!-- banner -->
        <div class="vip-exchange-banner" v-if="info.master.banner">
            <van-image width="100%" height="200px" :src="info.master.banner">
                <template slot="loading">
                    <van-loading type="spinner" size="20"/>
                </template>
            </van-image>
        </div>
        <!-- 介绍 -->
        <div class="vip-exchange-desc" v-html="info.master.detail"/>
        <!-- 套餐包 -->
        <div class="vip-exchange-package">
            <!-- 套餐tab按钮 -->
            <van-row type="flex" justify="space-around" class="vip-exchange-package-tabs">
                <van-col v-for="(item, index) in info.goods" v-bind:key="index" :span="18/info.goods.length"
                         :class="{'vip-exchange-package-tab':true, 'cur':index==showIndex}"
                         @click="onChangePackage(index)">
                    {{item.title}}
                </van-col>
            </van-row>
            <!-- 套餐内容 -->
            <div class="vip-exchange-content" v-for="(item, index) in info.goods" v-bind:key="index"
                 v-show="index==showIndex">
                <!-- 套餐描述 -->
                <div class="vip-exchange-item-desc">
                    <!-- 自定义内容 -->
                    <div class="vip-exchange-item-html" v-html="item.detail"/>
                    <!-- 套餐配置 -->
                    <div class="vip-exchange-item-config">
                        <van-row type="flex" justify="center" class="vip-exchange-item-config-item"
                                 v-for="(config,configIndex) in item.config" v-bind:key="configIndex">
                            <van-col span="11" class="title">{{config.title}}</van-col>
                            <van-col span="2" class="num">x{{config.num}}</van-col>
                            <van-col span="11" class="info">（{{config.info}}）</van-col>
                        </van-row>
                    </div>
                    <!-- 特别说明 -->
                    <div class="vip-exchange-item-info">
                        {{item.info}}
                    </div>
                </div>
                <!-- 兑换表单 -->
                <div class="vip-exchange-form">
                    <van-cell-group class="vip-exchange-form-group">
                        <van-field v-model="mobile" clearable type="number" left-icon="my iconfont icon-shouji"
                                   placeholder="请输入会员手机号" maxlength="11"/>
                    </van-cell-group>
                    <van-button size="large" color="#537fca" class="vip-exchange-btn" @click="onExchange">
                        兑换{{item.title}}
                    </van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'vip-exchange-detail',
        props: ['node', 'param'],
        data() {
            return {
                info: {
                    master: {
                        banner: ''
                    },
                    goods: []
                },
                mobile: '',
                showIndex: 0,
                showPassword: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                let that = this;
                that.$api.company.exchange.detail(that.param.pid).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.info = res.data;
                    } else {
                        that.$notify({type: 'warning', message: res.msg})
                    }
                })
            },
            onChangePackage(index) {
                this.showIndex = index
            },
            onExchange() {
                let that = this;
                if (!that.mobile || that.mobile < 11) {
                    return that.$toast('请输入会员手机号')
                }
                that.$dialog.confirm({
                    title: '温馨提示',
                    message: '兑换后不可更改，确认兑换吗？'
                }).then(() => {
                    that.$api.company.exchange.exchange({
                        id: that.param.id,
                        pwd: that.param.pwd,
                        itemId: that.info.goods[that.showIndex].id,
                        mobile: that.mobile
                    }).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.$toast('套餐兑换成功')
                        } else {
                            that.$toast(res.msg)
                        }
                    });
                }).catch(() => {
                    // on cancel
                });
            }
        }
    }
</script>

<style scoped>

    .vip-exchange {
        background: #f0f0f0;
        bottom: 0;
        overflow-y: scroll;
        width: 100%;
    }

    .vip-exchange-banner {

    }

    .vip-exchange-desc {
        text-align: left;
        padding: 10px 16px;
        color: #030303;
    }

    .vip-exchange-desc h3 {
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
    }

    .vip-exchange-desc p {
        line-height: 20px;
    }

    .vip-exchange-desc p.chinese {
        color: #222;
    }

    .vip-exchange-desc p.english {
        color: #515151;
        line-height: 20px;
    }

    .vip-exchange-package {
        margin: 0 10px;
    }

    .vip-exchange-package-tabs {
        font-size: 16px;
    }

    .vip-exchange-package-tab {
        background-color: #fff;
        color: #000;
        border-radius: 20px;
        border: 1px solid #8c8c8c;
        padding: 10px;
        margin-bottom: 36px;
        position: relative;
        text-align: center;
    }

    .vip-exchange-package-tab.cur {
        background-color: #537fca;
        color: #fff;
        border: 1px solid #537fca;
    }

    .vip-exchange-package-tab.cur::after {
        content: ' ';
        pointer-events: none;
        height: 36px;
        background: url(/static/images/vip-exchange/up-arrow.png) bottom center no-repeat;
        position: absolute;
        width: 100%;
        left: 0;
        top: 45px;
    }

    .vip-exchange-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
    }

    .vip-exchange-item-info {
        color: #565656;
        line-height: 30px;
        font-size: 14px;
        text-align: left;
    }

    .vip-exchange-item-config {
        margin: 10px 0;
    }

    .vip-exchange-item-config-item {
        color: #323232;
        font-size: 14px;
        line-height: 30px;
    }

    .vip-exchange-item-config-item .title {
        font-size: 16px;
        font-weight: bold;
        text-align: left;
    }

    .vip-exchange-item-config-item .num {
        font-weight: bold;
        color: #2a61be;
        text-align: left;
    }

    .vip-exchange-item-config-item .info {
        text-align: right;
    }

    .vip-exchange-form {
        margin-top: 30px;
    }

    .vip-exchange-form-group .van-cell {
        background: #f0f0f0;
        margin-bottom: 10px;
        border-radius: 10px;
    }

    .vip-exchange .van-cell__value {
        line-height: 24px !important;
        font-size: 14px;
    }

    .vip-exchange-btn {
        border-radius: 5px;
        padding: 5px 0;
        height: auto !important;
    }

    .vip-exchange .van-field__control {
        height: 24px;
        line-height: 24px;
    }
</style>
